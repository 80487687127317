import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div align='center'>
  <img src='https://raw.githubusercontent.com/pismo/bolt/develop/boltLogo.svg?sanitize=true' alt='Bolt' width='300' />
    </div>
    <h2 {...{
      "id": "bolt-é-um-conjunto-de-estilos-e-componentes-para-aplicações-front-end-que-faz-parte-do-design-system-da-pismo"
    }}>{`Bolt é um conjunto de estilos e componentes para aplicações front-end que faz parte do design system da Pismo.`}</h2>
    <br /><br />
# Instalação
    <p><inlineCode parentName="p">{`npm install @pismo/bolt`}</inlineCode></p>
    <h1 {...{
      "id": "uso"
    }}>{`Uso`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import '@pismo/bolt/dist/css/bolt-css.css'
import {Sidebar} from '@pismo/bolt'
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Devido ao tamanho da biblioteca css adicione o plugin `}<strong parentName="p">{`postcss-purge`}</strong>{` conforme descrito abaixo:`}</p>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`npm i @fullhuman/postcss-purgecss -D

/* package.json */
"scripts": {
 "postbuild": "purgecss --css build/static/css/*.css --content build/index.html build/static/js/*.js --output build/static/css"
}
`}</code></pre>
      <p parentName="blockquote">{`substitua o `}<em parentName="p">{`build/static`}</em>{` pela pasta de build do seu projeto`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Acesse a documentação para mais detalhes da utilização de cada componente`}</p>
    </blockquote>
    <br />
    <h1 {...{
      "id": "desenvolvimento"
    }}>{`Desenvolvimento`}</h1>
    <p>{`Após clonar o projeto, crie uma branch à partir da master e faça checkout.`}</p>
    <p>{`Após certificar que está na branch de trabalho execute o comando a seguir para baixar as dependencias do projeto:`}</p>
    <p><inlineCode parentName="p">{`yarn`}</inlineCode></p>
    <p>{`O componentes estão separados por pastas dentro da pasta `}<strong parentName="p">{`src`}</strong>{`. Cada pasta de componente deve conter: ou um arquivo `}<strong parentName="p">{`.css`}</strong>{`, ou um arquivo `}<strong parentName="p">{`.ts`}</strong>{`, ou ambos e um arquivo `}<strong parentName="p">{`.mdx`}</strong>{` para a documentação.`}</p>
    <p>{`O processo de desenvolvimento é feito sobre a documentação com `}<strong parentName="p">{`docz`}</strong>{`. Para executar a documentação em modo de desenvolvimento rode o comando no terminal: `}</p>
    <p><inlineCode parentName="p">{`yarn docz:dev`}</inlineCode></p>
    <p>{`Para iniciar o desenvolvimento, vamos rodar o build do css e do typescript em modo watch. Para isso execute o comando a seguir em outro terminal:`}</p>
    <p><inlineCode parentName="p">{`yarn dev`}</inlineCode></p>
    <br />
    <h1 {...{
      "id": "documentação"
    }}>{`Documentação`}</h1>
    <p>{`Após a conclusão do desenvolvimento, o componente deve ser totalmente documentado. A documentação é feita utilizando mdx com base em React.`}</p>
    <br />
    <h1 {...{
      "id": "testes"
    }}>{`Testes`}</h1>
    <p>{`Os testes são no formato `}<strong parentName="p">{`e2e`}</strong>{`, e é executado em cima do `}<strong parentName="p">{`docz`}</strong>{` e `}<strong parentName="p">{`cypress`}</strong>{`. Para rodar um teste, monte o componente no formato mdx na pasta `}<em parentName="p">{`_`}{`_`}{`test`}{`_`}{`_`}</em>{`. Escreva o teste na pasta `}<em parentName="p">{`cypress/integration`}</em>{`.
Para executar em modo de desenvolvimento rode o comando em um terminal:`}</p>
    <p><inlineCode parentName="p">{`yarn docz:test`}</inlineCode></p>
    <p>{`Em outro terminal rode o comando:`}</p>
    <p><inlineCode parentName="p">{`yarn cy:open`}</inlineCode></p>
    <br />
    <h1 {...{
      "id": "commit"
    }}>{`Commit`}</h1>
    <p>{`Para criar um commit execute o comando:`}</p>
    <p><inlineCode parentName="p">{`git commit`}</inlineCode></p>
    <p>{`Esse comando vai:`}</p>
    <ul>
      <li parentName="ul">{`buildar o projeto`}</li>
      <li parentName="ul">{`formatar todos os arquivos`}</li>
      <li parentName="ul">{`adicionar todos os arquivos adicionais ao stage`}</li>
      <li parentName="ul">{`rodar os testes`}</li>
    </ul>
    <p>{`Se os testes estiverem passando, em seguida será apresentado um menu para guiar no conventional commit.`}</p>
    <br />
    <h1 {...{
      "id": "release"
    }}>{`Release`}</h1>
    <p>{`O release deve ser feito na branch `}<strong parentName="p">{`master`}</strong>{` após a aprovação da PR.
Para gerar o release rode o comando:`}</p>
    <p><inlineCode parentName="p">{`yarn release`}</inlineCode></p>
    <p>{`Este comando vai mostrar um menu com opções para subir a versão do projeto, após a escolha serão executadas as tarefas a seguir de forma automatizada:`}</p>
    <ul>
      <li parentName="ul">{`sobe a versão no package.json`}</li>
      <li parentName="ul">{`cria o changelog de forma automatizada`}</li>
      <li parentName="ul">{`cria o commit`}</li>
      <li parentName="ul">{`cria a tag da versão`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Obs.: o push deve ser feito de forma manual`}</p>
    </blockquote>
    <br />
    <h1 {...{
      "id": "publish"
    }}>{`Publish`}</h1>
    <p>{`Após usbir a tag, deve ser criado a release no github e a plublicação no npm.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      